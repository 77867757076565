import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../../style/home.css";
import waitlistIcon from "../../../assets/waitlist-icon.png";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { IoIosArrowDown } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Modal from "../../modal/Modal";

const languages = {
  Arabic: "ar",
  Bulgarian: "bg",
  Catalan: "ca",
  Chinese: "zh",
  "Chinese (Mandarin, Traditional)": "zh-TW",
  "Chinese (Cantonese, Traditional)": "zh-HK",
  Czech: "cs",
  Danish: "da",
  Dutch: "nl",
  English: "en",
  Estonian: "et",
  Finnish: "fi",
  Flemish: "nl-BE",
  French: "fr",
  German: "de",
  "German (Switzerland)": "de-CH",
  Greek: "el",
  Hindi: "hi",
  Hungarian: "hu",
  Indonesian: "id",
  Italian: "it",
  Japanese: "ja",
  Korean: "ko",
  Latvian: "lv",
  Lithuanian: "lt",
  Malay: "ms",
  Norwegian: "no",
  Polish: "pl",
  Portuguese: "pt",
  Romanian: "ro",
  Russian: "ru",
  Slovak: "sk",
  Spanish: "es",
  Swedish: "sv",
  Thai: "th",
  Turkish: "tr",
  Ukrainian: "uk",
  Vietnamese: "vi",
};

const Waitlist = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [userValue, setUserValue] = useState({
    name: "",
    email: "",
    phone: "",
    // date: "",
    // time: "",
    url_type: "",
    pdf: null,
    url: [""],
  });
  const [formErrors, setFormErrors] = useState({ url: [] });
  const [options, setOptions] = useState([]);
  const [userTimeZone, setUserTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  useEffect(() => {
    if (props?.location?.showModal) {
      setIsModalOpen(props?.location?.showModal);
      navigate(".", { replace: true, state: {} });
    }
  }, [props?.location]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "url_type") {
      if (value === "pdf") {
        setUserValue({
          ...userValue,
          url_type: value,
          url: [""],
          pdf: null,
        });
      } else if (value === "website") {
        setUserValue({
          ...userValue,
          url_type: value,
          pdf: null,
        });
      } else {
        setUserValue({
          ...userValue,
          url_type: "",
          url: [""],
          pdf: null,
        });
      }
    } else {
      setUserValue({
        ...userValue,
        [name]: value,
      });
    }
  };

  // const handleDateChange = (date) => {
  //   setUserValue({
  //     ...userValue,
  //     date: date ? date.toISOString().split("T")[0] : "",
  //   });
  // };

  const handleTimeChange = (event) => {
    setUserValue({ ...userValue, time: event.target.value });
  };
  const handlePhoneChange = (value) => {
    setUserValue({ ...userValue, phone: value });
  };

  const handleBlur = (e) => {
    if (e.target?.name || e.target?.value) {
      const { name, value } = e.target;
      validateField(name, value);
    }
  };

  const validateField = (name, value, index) => {
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name = value ? "" : "Please enter your name";
        break;
      case "email":
        const emailPattern =
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        errors.email = value
          ? emailPattern.test(value)
            ? ""
            : "Please enter a valid email"
          : "Please enter your email";
        break;
      case "phone":
        errors.phone = value
          ? value.length >= 8
            ? ""
            : "Phone number should be at least 11 digits"
          : "Please enter your phone number";
        break;
      // case "date":
      //   errors.date = value ? "" : "Please select a date";
      //   break;
      // case "time":
      //   errors.time = value ? "" : "Please select a time";
      //   break;
      case "url_type":
        errors.url_type = value ? "" : "Please select URL type";
        break;
      case "pdf":
        if (userValue.url_type === "pdf") {
          errors.pdf = value ? "" : "Please upload a PDF";
        }
        break;
      case "url":
        if (userValue.url_type === "website" && Array.isArray(userValue.url)) {
          if (index !== undefined && index < userValue.url.length) {
            errors.url[index] = !value ? "Please enter a website URL" : "";
          }
        }
        break;
      case "language":
        errors.language = value ? "" : "Please select a language";
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    let errors = {};
    if (userValue?.url_type === "website") {
      const urlErrors =
        userValue &&
        userValue?.url?.map((url, index) => {
          if (!url) {
            valid = false;
            return "Please enter a website URL";
          } else if (
            !url.match(
              /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
            )
          ) {
            valid = false;
            return "Please enter a valid website URL";
          } else {
            return "";
          }
        });
      errors.url = urlErrors;
    }

    setFormErrors(errors);
    if (valid) {
      setLoading(true);
      try {
        const formData = new FormData();
        Object.keys(userValue).forEach((key) => {
          if (key === "url" && userValue.url_type === "pdf") {
            userValue.url.forEach((file, index) => {
              formData.append(`url`, file);
            });
          } else if (key === "url" && userValue.url_type === "website") {
            userValue.url.forEach((url, index) => {
              formData.append(`url[${index}]`, url);
            });
          } else {
            formData.append(key, userValue[key]);
          }
        });
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/register`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data?.status === 200) {
          toast.success("User Register Successfully!");
          localStorage.setItem("userToken", JSON.stringify(data?.data?.token));
          navigate(`/demo?token=${data?.data?.token}`);
          setUserValue({
            name: "",
            email: "",
            phone: "",
            // date: "",
            // time: "",
            url_type: "",
            pdf: null,
            url: [""],
          });
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePdfChange = (e) => {
    const files = Array.from(e.target?.files);
    setUserValue({
      ...userValue,
      url: files,
      pdf: files,
    });
  };

  const handleWebsiteUrlChange = (index, e) => {
    const { value } = e.target;
    const updatedWebsiteUrls = userValue?.url?.map((url, i) =>
      i === index ? value : url
    );
    setUserValue({
      ...userValue,
      url: updatedWebsiteUrls,
    });
    validateField("url", updatedWebsiteUrls, index);
  };

  const addWebsiteUrlField = () => {
    setUserValue({
      ...userValue,
      url: [...userValue?.url, ""],
    });
    setFormErrors({
      ...formErrors,
      url: [...formErrors?.url, ""],
    });
  };

  useEffect(() => {
    const utcTimes = ["06:00", "00:00", "18:00", "12:00"];

    const localTimes = utcTimes?.map((time) =>
      convertUTCToLocal(time, userTimeZone)
    );

    const formattedOptions = localTimes.map((localTime, index) => ({
      value: utcTimes[index],
      label: localTime,
    }));

    setOptions(formattedOptions);
  }, [userTimeZone]);

  function convertUTCToLocal(utcTime, timeZone) {
    const [utcHour, utcMinute] = utcTime?.split(":").map(Number);

    const nowLocal = new Date();
    const localYear = nowLocal.getFullYear();
    const localMonth = nowLocal.getMonth();
    const localDate = nowLocal.getDate();

    const utcDate = new Date(
      Date.UTC(localYear, localMonth, localDate, utcHour, utcMinute)
    );

    const options = {
      timeZone,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const localDateTime = new Intl.DateTimeFormat("en-US", options).format(
      utcDate
    );

    return localDateTime;
  }

  return (
    <>
    {console.log("isModalOpen", isModalOpen)}
    <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    <div className="waitlist-container">
      <div className="waitlist-wrapper section-spacing" ref={ref}>
        <div
          className={`waitlist-wrapper-content ${
            userValue?.url?.length > 2 ? "waitlist-bg" : ""
          }`}
        >
          <div className="waitlist-header">
            <div className="icon-container">
              <img src={waitlistIcon} alt="Robot Icon" className="robot-icon" />
            </div>
            <h2>Book Your Personalized Demo</h2>
          </div>
          <form className="waitlist-form">
            <div className="waitlist-form-wrapper">
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  value={userValue.name}
                  name="name"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
                {formErrors.name && (
                  <span className="error-message">{formErrors.name}</span>
                )}
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  value={userValue?.email}
                  name="email"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
                {formErrors.email && (
                  <span className="error-message">{formErrors?.email}</span>
                )}
              </div>
              <div>
                <PhoneInput
                  country={"us"}
                  value={userValue?.phone}
                  onChange={handlePhoneChange}
                  onBlur={handleBlur}
                  enableSearch={true}
                />
                {formErrors.phone && (
                  <span className="error-message">{formErrors?.phone}</span>
                )}
              </div>
              <div className="custom-select">
                <select
                  name="language"
                  value={userValue.language}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Language</option>
                  {Object.entries(languages).map(([name, code]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
                <IoIosArrowDown className="select-icon" />
                {formErrors.language && (
                  <span className="error-message">{formErrors.language}</span>
                )}
              </div>
              <div className="custom-select">
                <select
                  name="url_type"
                  value={userValue.url_type}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Your Data Type (URL/PDF)</option>
                  <option value="pdf">PDF</option>
                  <option value="website">URL</option>
                </select>
                <IoIosArrowDown className="select-icon" />

                {formErrors.url_type && (
                  <span className="error-message">{formErrors.url_type}</span>
                )}
              </div>
              {userValue?.url_type === "pdf" && (
                <div>
                  <input
                    type="file"
                    accept="application/pdf"
                    name="pdf"
                    onChange={handlePdfChange}
                    onBlur={handleBlur}
                    multiple
                  />
                  {formErrors?.pdf && (
                    <span className="error-message">{formErrors.pdf}</span>
                  )}
                </div>
              )}

              {userValue?.url_type === "website" &&
                userValue?.url?.map((url, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      placeholder="Website URL"
                      value={url}
                      onChange={(e) => handleWebsiteUrlChange(index, e)}
                      onBlur={(e) => handleBlur(index, e)}
                      name={`url${index}`}
                    />
                    {formErrors?.url && formErrors?.url[index] && (
                      <span className="error-message">
                        {formErrors?.url[index]}
                      </span>
                    )}
                  </div>
                ))}
              {userValue?.url_type === "website" &&
                userValue?.url?.length < 5 && (
                  <button
                    type="button"
                    onClick={addWebsiteUrlField}
                    className="add-url-button"
                  >
                    Add another URL
                  </button>
                )}

              {/* <div>
                <ReactDatePicker
                  selected={userValue?.date}
                  onChange={handleDateChange}
                  onBlur={handleBlur}
                  placeholderText="dd-mm-yyyy"
                  dateFormat="dd-MM-yyyy"
                  showIcon={true}
                  minDate={addDays(new Date(), 1)}
                />
                {formErrors?.date && (
                  <span className="error-message">{formErrors?.date}</span>
                )}
              </div> */}
              {/* <div className="custom-select">
                <select
                  name="time"
                  value={userValue?.time}
                  onChange={handleTimeChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Time</option>
                  {options.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </select>
                <IoIosArrowDown className="select-icon" />

                {formErrors?.time && (
                  <span className="error-message">{formErrors?.time}</span>
                )}
              </div> */}
            </div>
          </form>
          <button
            type="submit"
            className="btn-primary waitlist-form-btn"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <BeatLoader size={8} color={"#fff"} />
            ) : (
              "Book your demo"
            )}
          </button>
        </div>
      </div>
    </div>
    </>
  );
});

export default Waitlist;
